(() => {
  const options = {
    root: null,
    rootMargin: "0px 0px 0px 0px",
    threshold: 0.4,
  };
  let menuItems;
  let observers = [];
  let currentNavId;

  const setActiveLink = (entry) => {
    let activeMenuItem =
      currentNavId === "flyout-menu" && entry.target.id === "welcome"
        ? document.querySelector("#mobile-logo-link")
        : document.querySelector(
          `#${currentNavId} li a[href="#${entry.target.id}"]`
        );

    if (activeMenuItem) {
      menuItems.forEach((menuItem) =>
        menuItem.parentElement.classList.remove("active")
      );
      activeMenuItem.parentElement.classList.add("active");
    }
  };

  const setAddressHash = (id) => {
    window.history.replaceState({}, "", `#${id}`);
  }

  const getThreshold = (targetId, sectionHeight, viewportHeight) => {
    let targetVisiblePercent = 0.40;
    if (targetId === "locations") {
      targetVisiblePercent = 0.60;
    }
    return (viewportHeight * targetVisiblePercent) / sectionHeight;
  };

  const resizeObserver = new ResizeObserver((entries) => {
    observers.forEach((observer) => observer.disconnect());
    observers = [];
    const width = entries[0].contentRect.width;

    const isMobile = width <= 767;
    const menuQuerySelector = isMobile ? "#flyout-menu a, #mobile-logo-link" : "#topbar-menu a, #desktop-logo-link";

    menuItems = document.querySelectorAll(menuQuerySelector);
    currentNavId = isMobile ? "flyout-menu" : "topbar-menu";

    menuItems.forEach((linkElement) => {
      const targetId = linkElement.getAttribute("href").split("#")[1];
      const targetElement = document.getElementById(targetId);
      if (!targetElement) return;

      const sectionHeight = targetElement.offsetHeight;
      const viewportHeight = window.innerHeight;
      options.threshold = getThreshold(
        targetId,
        sectionHeight,
        viewportHeight
      );


      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveLink(entry);
            setAddressHash(entry.target.id);
          }
        });
      }, options);

      observer.observe(targetElement);
      observers.push(observer);
    });
  });

  resizeObserver.observe(document.body);

  window.addEventListener(
    "unload",
    () => {
      resizeObserver.disconnect();
      observers.forEach((observer) => observer.disconnect());
    },
    { passive: true }
  );
})();
